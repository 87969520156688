const ORDER_TABLE_CONFIG = {
    header_fields: [
        {
            label: 'DocEntry',
            key: "DocEntry",
            min_width: 100,
            max_width: 100
        },
    ]
}

const COMPLETE_ORDERS = {
    header_fields: [
        {
            label: 'Order No',
            key: "OrderNumber",
            min_width: 100,
            max_width: 100
        },
        {
            label: 'CardName',
            key: "CardName",
            min_width: 150,
            max_width: 250
        },
        {
            label: 'DocTotal',
            key: "DocTotal",
            min_width: 100,
            max_width: 100
        },
        {
            label: 'Doc Date',
            key: "DocDate",
            min_width: 150,
            max_width: 200
        },
        {
            label: 'Print Order Label',
            key: "U_TrackingNumber",
            min_width: 150,
            max_width: 200
        }
    ]
}

const PICK_STATUS_COLOR_CONFIG = {
    "Not Pickable" : "bg-red-500",
    "Picked": "bg-green-500",
    "Partially Pickable": "bg-[#0dcaf0]",
    "Fully Pickable":"bg-blue-500"
}

export {
    ORDER_TABLE_CONFIG,
    PICK_STATUS_COLOR_CONFIG,
    COMPLETE_ORDERS
}