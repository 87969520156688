export enum LOCAL_STORE_KEYS {
    WAREHOUSE_MANAGEMENT = 'warehouse-management',
    SELECTED_ORDERS = 'selected-orders',
    ORDERS = 'order-list',
    ORDER_TYPE = 'order-type',
    SOCKET_LOG = 'socket-log',
    ORDER_COMPLETION = 'order-completion',
    PAGE_STATES = 'page-states',
    DOCUMENT_MODEL = 'document-model',
    SOCKET_CONNECTION = 'socket-connection'
}

export enum ORDER_COMPLETION_KEYS {
    FULFILL_STATUS = 'fulfilStatus',
    DOCUMENT_NOT_FOUND = 'documentNotFount'
}

export enum DOCUMENT_MODEL_KEYS {
    DOC_ENTRY = 'DocEntry',
    ORDER_NUMBER = 'OrderNumber',
    CARD_CODE = 'CardCode',
    CARD_NAME = 'CardName',
    ADDRESS_OBJ = 'AddressObj'
}

export enum PAGE_STATES {
    LOADING = 'loading',
    MUTATE = 'mutate'
}