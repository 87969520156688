import _ from 'lodash'
import {erpMethodApi} from "@dladio/service";
import {toStringWithSlash} from "@dladio/utils";
import {generateReport, BULK_PICKING_LIST} from "./generate-report.action";
import {EXEC_QUERY_METHOD, EXEC_SAP_SERVICE} from "@dladio/service/dist/const/erp-method.consts";
import {extendedApiSlice} from "../service/custome-api.service";

export const bulkPickingList = (list: any) => {
    return async (dispatch: any) => {

        const {error, data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: EXEC_QUERY_METHOD,
            body: {
                name: 'ddbddeff36',
                value: list?.reduce((p: any, c: any) => p + `,${c?.DocEntry}`, list[0]?.DocEntry)
            },
        }))

        if (!error) {
            const {error, data: process} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
                method: EXEC_QUERY_METHOD,
                body: {
                    name: 'b0f65cc544',
                    value: toStringWithSlash(_.get(data, 'message'))
                },
            }))

            if (!error) {
                const {error, data: misc} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
                    method: EXEC_QUERY_METHOD,
                    body: {
                        name: 'f98c904226',
                        value: toStringWithSlash(_.get(process, 'message.value.DocumentLinesBinAllocations'))
                    },
                }))

                if (!error) {
                    generateReport(BULK_PICKING_LIST, {
                        partialPick: _.get(process, 'message.meta.value'),
                        fullPick: _.get(misc, 'message.value'),
                        status: _.get(process, 'message.meta.status')
                    }, dispatch)

                    return {error: false, message: ''}

                } else {
                    return {error: true, message: error}
                }
            }
        }
    }
}

export const saveDoc = (endpoint: any, body: any) => {
    return async (dispatch: any) => {
        const result = await dispatch(extendedApiSlice.endpoints.execMutationApi.initiate({
            method: EXEC_SAP_SERVICE,
            body: {
                "method": "POST",
                "endpoint": "DBulkPicking",
                "body": body
            },
        }))

        return result
    }
}

export const callMutationMethod = (method: any, body: any) => {
    return async (dispatch: any) => {
        const result = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: method,
            body: body,
        }))

        return result
    }
}

export const callMethod = (method: any, body: any) => {
    return async (dispatch: any) => {
        const result = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: method,
            body: body,
        }))

        return result
    }
}

export const execMethod = (method: any, body: any,options = {}) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: method,
            body: body,
        },options))

        if (!error) return data
        else throw error
    }
}

export const extendedExecMethod = (method: any, body: any, options = {}) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(extendedApiSlice.endpoints.extendedExecMethod.initiate({
            method: method,
            body: body,
        }, options))

        if (!error) return data
        else throw error
    }
}

export const execMutationMethod = (method: any, body: any) => {
    return async (dispatch: any) => {
        const {data, error} = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: method,
            body: body,
        }))

        if (!error) return data
        else throw error
    }
}

export const getPickStatusAction = (docNumber: any) => {
    return async (dispatch: any) => {
        const {data, error}: any = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: EXEC_QUERY_METHOD,
            body: {
                name: '56d607d3a1',
                id: docNumber

            },
        }, {forceRefetch: true}))

        if (!error) {
            return {
                error: false,
                data:  _.get(data, 'message.value'),
            }

        } else {
            return {error: true, data: ''}
        }
    }
}
