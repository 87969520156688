import _ from "lodash";
import {Box} from "@dladio/core-ui";
import EditeForm from "./editeForm";
import {QUERIES} from "../../../../consts/queries.const";
import {useState} from "react";
import {useDerive} from "@dladio/service";
import {METHOD_API} from "../../../../consts/method.const";
import {useDispatch} from "react-redux";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {showConfirmDialog} from "../../../../store/reducers/confirm-dialog";
import {execMutationMethod} from "../../../../actions/actions";
import {localStoreThunk, useLocalStore} from "@dladio/hooks";
import {DefaultButton, PrimaryButton, Text} from "@fluentui/react";
import {middleErrorToast, middleSuccessToast} from "../../../../service/toast.service";
import {DOCUMENT_MODEL_KEYS, LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";

const {setStore: setPageState} = localStoreThunk(LOCAL_STORE_KEYS.PAGE_STATES)

const PackedCartonInformation = ({data}: any) => {
    const [isEdite, setIsEdite] = useState(false)
    const {model} = useLocalStore(LOCAL_STORE_KEYS.DOCUMENT_MODEL)
    const dispatch = useDispatch()

    const {value: cartonDetails}: any = useDerive('exec', {
        options: {
            "method": EXEC_QUERY_METHOD,
            "body": {
                "name": QUERIES.GET_CARTON_DETAILS,
                "id": _.get(data, 'U_CartonCode')
            },
        },
        path: 'message'
    })

    const markAsComplete = () => {
        dispatch(
            showConfirmDialog({
                title: "Confirmation Required!",
                subtitle: "Are you sure you want to continue with the actions",
                onConfirm: async () => {
                    dispatch(setPageState(PAGE_STATES.LOADING, true) as any)
                    dispatch(execMutationMethod(METHOD_API.MARK_AS_COMPLETE, {
                        docEntry: _.get(model, DOCUMENT_MODEL_KEYS.DOC_ENTRY)
                    }) as any)
                        .then((data: any) => {
                            middleSuccessToast("Order completed")
                        })
                        .catch((e: any) => middleErrorToast("Cannot complete order"))
                        .finally(() => dispatch(setPageState(PAGE_STATES.LOADING, false) as any))
                }
            }))
    }

    const viewDetailsMarkup = (
        <>
            <div className={'flex flex-col'}>
                <Text className={'font-bold'}>Master carton</Text>
                <Text>{_.get(cartonDetails, 'type')}</Text>
            </div>
            <div className={'flex flex-col'}>
                <Text className={'font-bold'}>weight</Text>
                <Text>{_.get(data, 'U_Weight')}</Text>
            </div>
            <div className={'space-x-5 font-Popings mt-2'}>
                <Text className={'font-bold'}>Length : {_.get(cartonDetails, 'length')}</Text>
                <Text className={'font-bold'}>Width : {_.get(cartonDetails, 'width')}</Text>
                <Text className={'font-bold'}>Depth : {_.get(cartonDetails, 'depth')}</Text>
            </div>
        </>
    )

    return (
        <Box>
            {isEdite ? <EditeForm defaultData={data} onClose={setIsEdite}/> : viewDetailsMarkup}
            {!isEdite &&
                <PrimaryButton onClick={markAsComplete} className={'mt-2 mr-3'}>Complete the order</PrimaryButton>}
            {!isEdite && <DefaultButton onClick={() => setIsEdite(!isEdite)} className={'mt-2'}>Edit</DefaultButton>}
        </Box>

    )
}

export default PackedCartonInformation
