import {useAutoFit} from "@dladio/hooks";
import {multiSearch} from "@dladio/utils";
import React, {useEffect, useState} from "react";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    IDetailsListStyles,
} from "@fluentui/react";
import OrderLine from '../../pages/warehouse-management/components/order-list/components/order-line';
import DeliveryDocLine
    from '../../pages/warehouse-management/components/delivery-document-list/components/delivery-doc-line';
import {selectionMode, selections} from '../../consts/table-selection.const';

interface IProps {
    config: any,
    model: any
    type: any
}

const Table = ({config, model, type}: IProps) => {
    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 400)
    }, [])

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
                key: index,
                name: line.label,
                fieldName: line.key,
                page: line.page,
                dataType: line?.type === 'divider' ? null : line.data_type,
                meta: line?.field_meta,
                isResizable: true,
                minWidth: line?.min_width,
                maxWidth: line?.max_width,
                isSortedDescending: false,
                isSorted: false,
                styles: {root: {backgroundColor: '#f0f0f0'}},
                onColumnClick: _onColumnClick
            })
        )

        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    const onRenderOrderItemColumn = (model: any, index: any, column: any) => {
        switch (type) {
            case 'ORDER': {
                return <OrderLine {...model} />
            }

            case 'PICK': {
                return <DeliveryDocLine type={'PICK'} {...model} />
            }

            case "PACK": {
                return <DeliveryDocLine type={'PACK'} {...model} />
            }
        }

    }

    let className = "overflow-auto h-lg mt-4"

    return (
        <div ref={ref} className={className}>
            <DetailsList
                items={lines}
                columns={columns}
                selection={selections[type]}
                compact={true}
                styles={gridStyles}
                setKey="none"
                onRenderItemColumn={onRenderOrderItemColumn}
                onRenderDetailsHeader={_onRenderDetailHeader}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={selectionMode[type]}
                selectionPreservedOnEmptyClick={true}
                isHeaderVisible={false}
            />
        </div>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: "hidden",
        overflowX: 'hidden',
    }
};

export default Table