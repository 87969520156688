import _ from "lodash";
import * as yup from 'yup';
import {METHOD_API} from "../../../../consts/method.const";
import {useDispatch} from "react-redux";
import CartonDetails from "../CartonDetails/CartonDetails";
import {FK, FORM_KEYS} from "../../../../consts/form.const";
import {showConfirmDialog} from "../../../../store/reducers/confirm-dialog";
import {execMutationMethod} from "../../../../actions/actions";
import {PrimaryButton, TextField} from "@fluentui/react";
import {AutoCompleteTextField, Box} from "@dladio/core-ui";
import {middleErrorToast, middleSuccessToast} from "../../../../service/toast.service";
import {localStoreThunk, useForm, useLocalStore} from "@dladio/hooks";
import {DOCUMENT_MODEL_KEYS, LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";

let schema = yup.object().shape({
    docEntry: yup.number().required(),
    weight: yup.string().required(),
    cartonCode: yup.string().required(),
});

const {setStore: setPageState} = localStoreThunk(LOCAL_STORE_KEYS.PAGE_STATES)

const Packing = () => {
    const dispatch = useDispatch()
    const {model} = useLocalStore(LOCAL_STORE_KEYS.DOCUMENT_MODEL)
    const {form, formController, clearForm} = useForm(FORM_KEYS.PACKING_FORM)

    const save = () => {
        dispatch(
            showConfirmDialog({
                title: "Confirmation Required!",
                subtitle: "Are you sure you want to continue with the actions",
                onConfirm: async () => {
                    let formModel = {docEntry: _.get(model, DOCUMENT_MODEL_KEYS.DOC_ENTRY), ...form}
                    schema.isValid(formModel)
                        .then(function (valid) {
                            if (valid) {
                                dispatch(setPageState(PAGE_STATES.LOADING, true) as any)
                                dispatch(execMutationMethod(METHOD_API.MARK_AS_PACK, formModel) as any)
                                    .then((data: any) => {
                                        clearForm([])
                                        middleSuccessToast("Saved carton details")
                                    })
                                    .catch((e: any) => middleErrorToast("Cannot save carton details"))
                                    .finally(() => dispatch(setPageState(PAGE_STATES.LOADING, false) as any))
                            } else middleErrorToast('Form is invalid')
                        });
                }
            }))
    }

    return (
        <Box>
            <AutoCompleteTextField
                defaultKey={_.get(form, FK.MASTER_CARTON)}
                className={'w-[286px]'}
                label={'Master carton'}
                id={FK.MASTER_CARTON}
                onChange={formController}
                options={{
                    type: "erp",
                    query: {name: '152b2768d0', id: ''}
                }}
                required
            />
            <TextField value={_.get(form, FK.WEIGHT)} onChange={formController} className={'w-[286px]'} name={FK.WEIGHT}
                       label={'Weight'} required/>
            {_.get(form, FK.MASTER_CARTON) &&
                <CartonDetails cartonId={_.get(form, FK.MASTER_CARTON) ? _.get(form, FK.MASTER_CARTON) : ''}/>}
            <PrimaryButton className={'mt-2'} onClick={save}>Save</PrimaryButton>
        </Box>
    )
}

export default Packing