import {createSlice} from '@reduxjs/toolkit'

interface IModalState {
    visible: boolean
    model: {
        title?: string,
        subtitle?: string,
        onConfirm: () => void
    }
}

const initialState = {
    visible: false,
    model: {}
} as IModalState

const slice = createSlice({
    name: 'confirm-dialog-store',
    initialState,
    reducers: {
        showConfirmDialog(state, {payload}) {
            state.visible = true
            state.model = payload
        },
        hideConfirmDialog(state) {
            state.visible = false
        }
    },
})

export const {showConfirmDialog, hideConfirmDialog} = slice.actions
export default slice.reducer