import _ from "lodash";
import {FK} from "../../../../consts/form.const";
import {ORDER_STATUS} from "../../../../consts/const";
import {useForm, useLocalStore} from "@dladio/hooks";
import {MessageBar, MessageBarType} from "@fluentui/react";
import {LOCAL_STORE_KEYS, ORDER_COMPLETION_KEYS} from "../../../../consts/local-store.const";

const messageModel: any = {
    'undefined': "The order is not found",
    'null': "The order is not found",
    [ORDER_STATUS.PICK]: "Order is not completely pick",
    [ORDER_STATUS.PACK]: "Order picking is completed",
    [ORDER_STATUS.READY_TO_COMPLETE]: "Packing information are saved you should complete the order",
    [ORDER_STATUS.COMPLETE]: "This is a completed order"
}

const typeModel: any = {
    'undefined': MessageBarType.error,
    'null': MessageBarType.error,
    [ORDER_STATUS.PICK]: MessageBarType.severeWarning,
    [ORDER_STATUS.PACK]: MessageBarType.warning,
    [ORDER_STATUS.READY_TO_COMPLETE]: MessageBarType.warning,
    [ORDER_STATUS.COMPLETE]: MessageBarType.success
}

const OrderStatus = () => {
    const {form} = useForm()
    const {model: OCModel} = useLocalStore(LOCAL_STORE_KEYS.ORDER_COMPLETION)

    const docEntry = _.get(form, FK.DOC_ENTRY)
    const fulFillStatus = _.get(OCModel, ORDER_COMPLETION_KEYS.FULFILL_STATUS)

    const markup = docEntry && fulFillStatus && (
        <div className={'px-2 pb-2 bg-[#f0f0f0]'}>
            <MessageBar className={'rounded-md'} messageBarType={typeModel[fulFillStatus]}>{messageModel[fulFillStatus]}</MessageBar>
        </div>
    )

    return <>{markup}</>
}

export default OrderStatus