import _ from "lodash";
import Loading from "../../../complete-order-packing/componets/Loading/Loading";
import {QUERIES} from "../../../../consts/queries.const";
import {FORM_KEYS} from "../../../../consts/form.const";
import {useDerive} from "@dladio/service";
import {COMPLETE_ORDERS} from "../../../../consts/table-config.const";
import CompleteOrderTable from "../Table/CompleteOrderTable";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {useForm, useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";
import AUDateFormatter, {getOnlyDate} from "@dladio/utils/dist/utils/date";

const TableContainer = () => {
    const {form} = useForm(FORM_KEYS.DATE_FILTER)
    const {model: PSModel}: any = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    const {value, isFetching} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD, body: {
                name: QUERIES.GET_COMPLETED_ORDERS,
                from: getOnlyDate(AUDateFormatter(_.get(form, 'from')))
            }
        },
        path: 'message'
    })

    return (
        <>
            {
                isFetching || isLoading ?
                    <Loading/> :
                    <CompleteOrderTable config={COMPLETE_ORDERS} model={_.isArray(value) ? value : []}/>
            }
        </>
    )
}
export default TableContainer