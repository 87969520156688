import _ from "lodash";
import {QUERIES} from "../../../../consts/queries.const";
import {isArray} from "@dladio/utils";
import {useDerive} from "@dladio/service";
import OrderStatusLine from "./components/order-status-line";
import {useLocalStore} from "@dladio/hooks";
import {LOCAL_STORE_KEYS} from "../../../../consts/local-store.const";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {SOCKET_RESPONSE_STATUS} from "../../../../consts/socket.const";

const OrderStatus = () => {
    const {model: socketLog} = useLocalStore(LOCAL_STORE_KEYS.SOCKET_LOG)

    const {data} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                name: QUERIES.GET_STATUS,
            }
        }, path: 'message'
    })

    const socketLogMarkup = isArray(_.get(socketLog, 'lines')) && _.get(socketLog, 'lines').map((log: any, idx: number) =>
        <StatusItem idx={idx} {...log}/>)

    return (
        <div className="h-[calc(100vh-155px)] flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
            <div className={"flex flex-col mt-2 gap-2 overflow-x-hidden overflow-y-auto"}>
                <OrderStatusLine label={'Picked'} value={_.get(data, 'message.PickedOrders')}/>
                <OrderStatusLine label={'Packed'} value={_.get(data, 'message.PackedOrders')}/>
                <OrderStatusLine label={'Completed'} value={_.get(data, 'message.CompletedOrders')}/>
                {socketLogMarkup}
            </div>
        </div>
    )
}

const StatusItem = ({status, payload, idx}: any) => {
    return (
        <div
            className={`w-full border rounded flex flex-col ${(status === SOCKET_RESPONSE_STATUS.SUCCESS) ? 'bg-green-200' : 'bg-gray-200'}  pb-3`}>
            <div className={"w-full border relative p-3 rounded flex justify-between items-center"}>
                <div className="flex items-center gap-1">
                    <h1 className="text-[10px] bg-orange-500 text-white p-0.5 rounded-full w-[20px] flex justify-center items-center">{idx + 1}</h1>
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                            <h1 className="text-[12px] font-semibold mt-0.5">{payload?.order}</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-3 flex flex-col gap-1 justify-center mt-3">
                {payload?.msg}
            </div>
        </div>
    )
}


export default OrderStatus