import Header from "./components/Header/Header";
import DateFilter from "./components/DateFilter/DateFilter";
import SocketManager from "../../components/SocketManger.tsx/SocketManager";
import TableContainer from "./components/TableContainer/TableContainer";

const CompleteOrderList = () => {

    return (
        <div>
            <Header/>
            <DateFilter/>
            <TableContainer/>
            <SocketManager event={'dln_to_inv'}/>
        </div>
    )
}

export default CompleteOrderList