import _ from "lodash";
import React from "react";
import Loading from "./componets/Loading/Loading";
import Packing from "./componets/Packing/Packing";
import Complete from "./componets/Complete/Complete";
import SearchBar from "./componets/SearchBar/SearchBar";
import OrderStatus from "./componets/OrderStatus/OrderStatus";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import {ORDER_STATUS} from "../../consts/const";
import {useLocalStore} from "@dladio/hooks";
import ReadyToComplete from "./componets/ReadyToComplete/ReadyToComplete";
import {dispatchAction} from "@dladio/app-bridge";
import OrderInformation from "./componets/OrderInformtion/OrderInformation";
import {ICommandBarItemProps} from "@fluentui/react";
import {DefaultCommandBar, TitleBar} from "@dladio/core-ui";
import {LOCAL_STORE_KEYS, ORDER_COMPLETION_KEYS, PAGE_STATES} from "../../consts/local-store.const";

const cmdBarConfig: ICommandBarItemProps[] = [
    {
        key: '1',
        text: 'Back',
        iconProps: {iconName: 'Back'},
        onClick: () => {
            dispatchAction({
                action: 'navigate',
                payload: -1
            }, "https://helios.dlad.io/")
        },
    },
    {
        key: '2',
        text: 'Complete Orders',
        iconProps: {iconName: 'PageLink'},
        onClick: () => {
            dispatchAction({
                action: 'navigate',
                payload: '/app/page/76dac5de3f'
            }, "https://helios.dlad.io/")
        },
    },
]

const markupModel = {
    [ORDER_STATUS.PACK]: Packing,
    [ORDER_STATUS.READY_TO_COMPLETE]: ReadyToComplete,
    [ORDER_STATUS.COMPLETE]: Complete
}

const CompleteOrderPacking = () => {

    const {model: PSModel} = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)
    const {model: OCModel} = useLocalStore(LOCAL_STORE_KEYS.ORDER_COMPLETION)

    const fulFillStatus = _.get(OCModel, ORDER_COMPLETION_KEYS.FULFILL_STATUS)
    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    return (
        <div>
            <>
                <ConfirmDialog/>
                <TitleBar
                    title="Complete Packing"
                    subTitle={'WMS'}
                />
                <DefaultCommandBar actions={cmdBarConfig as any}/>
                <SearchBar/>
                {isLoading ? <Loading/> :
                    <>
                        <OrderStatus/>
                        <OrderInformation/>
                        {_.get(markupModel, fulFillStatus) && React.createElement(_.get(markupModel, fulFillStatus))}
                    </>
                }

            </>
        </div>
    )
}
export default CompleteOrderPacking
