import PizZip from "pizzip";
import assign from "lodash/assign";
import Docxtemplater from "docxtemplater";
import {msGraphApi} from "@dladio/service";
// @ts-ignore
import expressions from 'angular-expressions';
import PizZipUtils from "pizzip/utils/index.js";
import {Providers} from "@microsoft/mgt-element";
import { dispatchAction } from "@dladio/app-bridge";
import {GRAPH_BASE} from "@dladio/service/dist/const/app.consts";
const MIME = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const BULK_PICKING_LIST = 'BulkPickingSlip.docx';
const FILE_BASE = '/print-templates';


const angularParser = (tag: any) => {
    if (tag === '.') {
        return {
            get: (s: any) => {
                return s;
            }
        };
    }

    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );

    return {
        get: (scope: any, context: any) => {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

const loadFile = (url: any, callback: any) => {
    PizZipUtils.getBinaryContent(url, callback);
}

const generateReport = (reportName: any, payload: any, dispatch: any) => {
    dispatchAction({
        action: 'loading',
        payload: true
    })

    loadFile(`${FILE_BASE}/${reportName}`,
        async (error: any, content: any) => {

            if (error) {
                throw error;
            }
            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                parser: angularParser, linebreaks: true
            });

            doc.render(payload);

            const out = doc.getZip().generate({
                type: "blob",
                mimeType: MIME,
            });

            let createDocResult: any = await createDocAction(out, payload, dispatch)
            let pdf:any = await getPDFofDocAction(payload)

            pdf.blob().then((pdf: any) => {
                const blobURL = URL.createObjectURL(pdf);

                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = () => {
                    setTimeout(() => {
                        iframe.focus();
                        // @ts-ignore
                        iframe.contentWindow.print();
                    }, 1);
                };
                dispatchAction({
                    action: 'loading',
                    payload: false
                })
            });

        }
    );
}

const createDocAction = async (content: any, doc: any, dispatch: any) => {
    try {
        const accessToken = await Providers.globalProvider.getAccessTokenForScopes('Files.ReadWrite.All')

        const url = `/drive/root:/sap-data/SO-${doc?.DocNum}.docx:/content`
        let response = await dispatch(msGraphApi.endpoints.execPut.initiate({
            url,
            body: content,
            accessToken
        }))
        return response

    } catch (error) {
        console.log(error)
    }
}

export const getPDFofDocAction = async (doc: any) => {
    try {
        const accessToken = await Providers.globalProvider.getAccessTokenForScopes('Files.ReadWrite.All')
        const url = `${GRAPH_BASE}v1.0/me/drive/root:/sap-data/SO-${doc.DocNum}.docx:/content?format=pdf`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${accessToken}`
            },
            referrerPolicy: 'no-referrer',
        });

        return response

    } catch (error) {
        console.log(error)
    }


}

export interface IFetchPrintModel {
    query: string,
    key: string,
    path: string
}

export {
    generateReport,
    BULK_PICKING_LIST,
}
