import {erpMethodApi} from "@dladio/service";

export const extendedApiSlice = erpMethodApi.injectEndpoints({
    endpoints: builder => ({
        extendedExecMethod: builder.query({
            query: ({ method, body }) => ({
                url: `${method}`,
                method: 'POST',
                body: body
            })
        })
    })
})

export const { useExtendedExecMethodQuery } = extendedApiSlice