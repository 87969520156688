import Table from "../../../../components/table/table";
import {QUERIES} from "../../../../consts/queries.const";
import {useDerive} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {ORDER_TABLE_CONFIG} from "../../../../consts/table-config.const";

const DeliveryDocumentListComponent = ({ type }: any) => {

    const { value, isFetching } = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD,
            body: {
                "name": QUERIES.GET_DELIVERY_LIST,
                "status": `${type}`,
            }
        }, path: 'message'
    })

    return (
        <div className="h-[calc(100vh-155px)] flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
            <div className="bg-orange-200 mt-2 p-3 rounded border border-yellow-500">
                <h1>{`${value?.length ?? 0} documents found`}</h1>
            </div>
            <Table config={ORDER_TABLE_CONFIG} type={type} model={value ?? []} />
        </div>
    )
}


export default DeliveryDocumentListComponent