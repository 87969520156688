import _ from "lodash";
import {Text} from "@fluentui/react";
import {useForm} from "@dladio/hooks";
import {QUERIES} from "../../../../consts/queries.const";
import {useDerive} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import PackedCartonInformation from "../PackedCartonInformation/PackedCartonInformation";

const ReadyToComplete = () => {
    const {form} = useForm()

    const {value}: any = useDerive('exec', {
        options: {
            "method": EXEC_QUERY_METHOD,
            "body": {
                "name": QUERIES.SELECTED_CARTON_DETAILS,
                "id": _.get(form, 'docEntry')
            },
        },
        path: 'message[0]'
    })

    return (
        !_.isEmpty(value) ? <PackedCartonInformation data={value} docEntry={_.get(form, 'docEntry')}/> : null
    )
}

interface ILineItem {
    title: string,
    value: string
}

const LineItem = ({title, value}: ILineItem) => {
    return (
        <div className={'flex flex-col'}>
            <Text className={'font-bold'}>{title}</Text>
            <Text>{value}</Text>
        </div>
    )
}

export default ReadyToComplete