import {io} from "socket.io-client";
import {CoreUIProvider} from "@dladio/core-ui";
import {BrowserRouter} from "react-router-dom";
import RouterConfigs from "./route/router.config";
import {AppBridgeProvider} from "@dladio/app-bridge";
// @ts-ignore
export const socket = io('wss://core.teadrop.us')

function App() {
    return (
        <AppBridgeProvider apiKey="wms">
            <CoreUIProvider>
                <BrowserRouter>
                    <>
                        <RouterConfigs/>
                    </>
                </BrowserRouter>
            </CoreUIProvider>
        </AppBridgeProvider>
    );
}

export default App;
