import _ from "lodash";
import {Box} from "@dladio/core-ui";
import {execMethod} from "../../../../actions/actions";
import {useAutoFit} from "@dladio/hooks";
import {METHOD_API} from "../../../../consts/method.const";
import {useDispatch} from "react-redux";
import {multiSearch} from "@dladio/utils";
import {dispatchAction} from "@dladio/app-bridge";
import React, {useEffect, useState} from "react";
import {completeOrderSelection} from "../../../../service/table-selection";
import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    Text,
    IDetailsHeaderProps,
    IDetailsListStyles, Link, SearchBox, SelectionMode, Icon,
} from "@fluentui/react";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {middleErrorToast} from "../../../../service/toast.service";
import AUDateFormatter, {getOnlyDate} from "@dladio/utils/dist/utils/date";

interface IProps {
    config: any,
    model: any
}

const CompleteOrderTable = ({config, model}: IProps) => {
    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [ref, setAutoFit] = useAutoFit()
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 400)
    }, [])

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    const getTrackingNumber = async (id: any) => {
        const data = await dispatch(execMethod(EXEC_QUERY_METHOD, {
            name: 'd62f5e9c03',
            id: id
        }, {forceRefetch: true}) as any)

        return data?.message
    }

    const printHandler = async (docEntry: any) => {

        const {IsExists, value: trackingNumber} = await getTrackingNumber(docEntry)

        if (IsExists) {
            dispatch(execMethod(METHOD_API.PRINT_LABEL, {
                tracking_number: trackingNumber
            }) as any).then(({message}: any) => {
                // @ts-ignore
                window.open(_.get(message, 'value'), '_blank').focus();
            })
        } else {
            middleErrorToast("The 'tracking number' does not exist")
        }

    }

    const onClickLink = (url: any) => {
        dispatchAction({
            action: 'navigate',
            payload: `/app/page/${url}`
        }, "https://helios.dlad.io/")
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);

        if (column.fieldName === 'CardName') {
            return (
                <Link onClick={() => {
                    onClickLink(`d929b94379/?id=${_.get(model, 'CardCode')}`)
                }} underline>
                    {fieldContent}
                </Link>
            )

        } else if (column.fieldName === 'DocDate') {
            return (
                <Text variant={"small"}>
                    {getOnlyDate(AUDateFormatter(fieldContent))}
                </Text>
            )

        } else if (column.fieldName === 'OrderNumber') {
            return (
                <Link onClick={() => {
                    onClickLink(`51d1ebb719/?id=${_.get(model, 'OrderDocEntry')}`)
                }} underline>
                    {fieldContent}
                </Link>
            )
        } else if (column.fieldName === 'U_TrackingNumber') {
            return (
                <Icon iconName={'Print'} className={'text-blue-600 hover:cursor-pointer pl-3'} onClick={() => {
                    printHandler(_.get(model, 'DocEntry'))
                }}/>
            )
        }

        return (
            <Text variant={"small"}>{fieldContent}</Text>
        )
    }

    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
                key: index,
                name: line.label,
                fieldName: line.key,
                page: line.page,
                dataType: line?.type === 'divider' ? null : line.data_type,
                meta: line?.field_meta,
                isResizable: true,
                minWidth: line?.min_width,
                maxWidth: line?.max_width,
                isSortedDescending: false,
                isSorted: false,
                styles: {root: {backgroundColor: '#f0f0f0'}},
                onColumnClick: _onColumnClick
            })
        )
        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    let className = "overflow-auto h-lg"

    return (
        <Box title={`Orders (${model?.length ?? 0})`}>
            <div className={'flex flex-wrap'}>
                <SearchBox onChange={searchTextChangeHandler} className="w-[280px] m-2 rounded-2" placeholder="Search"/>
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={completeOrderSelection}
                    compact={true}
                    styles={gridStyles}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.multiple}
                    selectionPreservedOnEmptyClick={true}
                />
            </div>
        </Box>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default CompleteOrderTable