import _ from "lodash";
import * as yup from "yup";
import {useEffect} from "react";
import {METHOD_API} from "../../../../consts/method.const";
import {useDispatch} from "react-redux";
import CartonDetails from "../CartonDetails/CartonDetails";
import {FK, FORM_KEYS} from "../../../../consts/form.const";
import {showConfirmDialog} from "../../../../store/reducers/confirm-dialog";
import {execMutationMethod} from "../../../../actions/actions";
import {AutoCompleteTextField} from "@dladio/core-ui";
import {useForm, useLocalStore} from "@dladio/hooks";
import {middleErrorToast, middleSuccessToast} from "../../../../service/toast.service";
import {DOCUMENT_MODEL_KEYS, LOCAL_STORE_KEYS} from "../../../../consts/local-store.const";
import {DefaultButton, PrimaryButton, TextField} from "@fluentui/react";

let schema = yup.object().shape({
    docEntry: yup.number().required(),
    weight: yup.string().required(),
    cartonCode: yup.string().required(),
});

const EditeForm = ({defaultData, onClose}: any) => {
    const {model} = useLocalStore(LOCAL_STORE_KEYS.DOCUMENT_MODEL)
    const {form, setForm, formController, clearForm} = useForm(FORM_KEYS.EditeForm)
    const dispatch = useDispatch()

    useEffect(() => {
        setForm({
            [FK.MASTER_CARTON]: _.get(defaultData, 'U_CartonCode'),
            [FK.WEIGHT]: _.get(defaultData, 'U_Weight'),
        })
    }, [defaultData])

    const closeHandler = () => {
        setForm({[FK.MASTER_CARTON]: _.get(defaultData, 'U_CartonCode')})
        onClose(false)
    }

    const saveHandler = () => {
        dispatch(
            showConfirmDialog({
                title: "Confirmation Required!",
                subtitle: "Are you sure you want to continue with the actions",
                onConfirm: async () => {
                    let formModel = {docEntry: _.get(model, DOCUMENT_MODEL_KEYS.DOC_ENTRY), ...form}
                    schema.isValid(formModel)
                        .then(function (valid) {
                            if (valid) {
                                dispatch(execMutationMethod(METHOD_API.MARK_AS_PACK, formModel) as any)
                                    .then((data: any) => {
                                        clearForm([])
                                        middleSuccessToast("Saved carton details")
                                    })
                                    .catch((e: any) => middleErrorToast("Cannot save carton details"))
                            } else middleErrorToast('Form is invalid')
                        });
                }
            }))
    }

    return (
        <div>
            <div>
                <AutoCompleteTextField
                    defaultKey={_.get(form, 'cartonCode')}
                    className={'w-[150px]'}
                    label={'Master carton'}
                    id={FK.MASTER_CARTON}
                    onChange={formController}
                    options={{
                        type: "erp",
                        query: {name: '152b2768d0'}
                    }}
                    required
                />
            </div>
            <div className={'flex flex-col'}>
                <TextField label={'Weight'} required className={'w-[150px]'} name={FK.WEIGHT}
                           value={_.get(form, FK.WEIGHT)}
                           onChange={formController}/>
            </div>
            <CartonDetails cartonId={_.get(form, 'cartonCode')}></CartonDetails>
            <PrimaryButton className={'mt-2 mr-3'} onClick={saveHandler}>Save</PrimaryButton>
            <DefaultButton className={'mt-2'} onClick={closeHandler}>Close</DefaultButton>
        </div>

    )
}

export default EditeForm