import _ from "lodash";
import { Box } from "@dladio/core-ui";
import { useState } from "react";
import { execMethod } from "../../../../actions/actions";
import { METHOD_API } from "../../../../consts/method.const";
import { useDispatch } from "react-redux";
import { useLocalStore } from "@dladio/hooks";
import { middleErrorToast } from "../../../../service/toast.service";
import { LOCAL_STORE_KEYS } from "../../../../consts/local-store.const";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import {SERVICE} from "@dladio/service"

const Complete = () => {
    const { model }: any = useLocalStore(LOCAL_STORE_KEYS.DOCUMENT_MODEL)
    const dispatch = useDispatch()
    const [loading, setLoading]: any = useState(false)

    const printHandler = async () => {
        setLoading(true)
        //TODO: this tracking number should get from the dlad_ordt table

        dispatch(execMethod(SERVICE.EXEC_QUERY_METHOD, {name: 'd62f5e9c03', id: _.get(model, 'DocEntry')}) as any)
            .then(({message}: any) => {
                
                dispatch(execMethod(METHOD_API.PRINT_LABEL, {
                    tracking_number: _.get(message, 'value')
                }) as any).then(({ message }: any) => {
                    // @ts-ignore
                    window.open(_.get(message, 'value'), '_blank').focus();
                }).catch((error: any) => middleErrorToast("Cannot print labels"))
                    .finally(() => setLoading(false))

            })
            .catch((error: any) => middleErrorToast("Cannot print labels"))
            .finally(() => setLoading(false))


    }

    return (
        <Box>
            <PrimaryButton onClick={printHandler} disabled={loading} className={'mt-2 '}>{loading && <Spinner styles={{ circle: { width: '9px', height: '9px' } }} size={SpinnerSize.xSmall} className={'mr-2 p-0'} />} Print Labels</PrimaryButton>
        </Box>
    )
}

export default Complete