import _ from "lodash";
import {Link} from "@fluentui/react";
import {PAGE_NO} from "../../../../../consts/pages.const";
import {useDispatch} from "react-redux";
import {dispatchAction} from "@dladio/app-bridge";
import {useEffect, useState} from "react";
import {getPickStatusAction} from "../../../../../actions/actions";

const OrderLine = (data: any) => {

    const dispatch = useDispatch()
    const [pickStatus, setPickStatus]: any = useState()
    let {DocNum, DocEntry, CardName, DocDate, FormattedDocTotal, CardCode}: any = data

    useEffect(() => {
        getPickStatus().then()
    }, [DocNum])

    const getPickStatus = async () => {
        let {error, data}: any = await dispatch(getPickStatusAction(DocEntry) as any)
        if (!error) setPickStatus(data)
    }

    const pickStatusBgColours = {
        "Fully Pickable": 'bg-green-500',
        "Partially Pickable": 'bg-amber-400',
        "Picked": 'bg-red-500',
        "loading": 'bg-black'
    }

    const bulkPickBgColour = {
        "1": 'bg-red-500',
        "0": 'bg-blue-500',
    }

    const urlByType = {
        order: PAGE_NO.ORDER + `?id=${DocEntry}`,
        bp: PAGE_NO.BP + `?id=${CardCode}`
    }

    const onClickLink = (type: any) => {
        dispatchAction({
            action: 'navigate',
            payload: `page/${_.get(urlByType, type)}`
        }, "https://helios.dlad.io/")
    }

    return (
        <div className="flex gap-5 w-full justify-start items-start">
            <div>
                <h1 className={"font-bold"}>Order No</h1>
                <p>BP</p>
                <p>Order Date</p>
                <p>Total</p>
                <p>Pick status</p>
                <p>BulkPick status</p>
            </div>
            <div className={'overflow-hidden'}>
                <h1 className={"font-bold"}>
                    <Link
                        onClick={() => onClickLink('order')}
                        underline>{DocNum}
                    </Link>
                </h1>
                <p className={'text-blue-600 underline w-[15vh] truncate cursor-pointer'}
                   onClick={() => onClickLink('bp')}
                > {CardName}
                </p>
                <p>{DocDate}</p>
                <p>{FormattedDocTotal}</p>
                <div className={'w-[15vh]'}>
                    <p className={`text-xs px-3 text-center text-white  border rounded-md ${_.get(pickStatusBgColours, pickStatus)}`}>{pickStatus}</p>
                    <p className={`text-xs px-3 text-center text-white border rounded-md ${_.get(bulkPickBgColour, _.get(data, 'BulkPickStatus'))}`}>{_.get(data, 'BulkPickStatus') ? 'Picked' : 'Not Picked'}</p>
                </div>
            </div>
        </div>
    )
}

export default OrderLine