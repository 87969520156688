import _ from "lodash";
import {useForm} from "@dladio/hooks";
import {FORM_KEYS} from "../../../../consts/form.const";
import {Box, Stack} from "@dladio/core-ui";
import React, {useEffect} from "react";
import {DatePicker, FontIcon} from "@fluentui/react";

const DateFilter = () => {
  const {form,setForm, clearForm} = useForm(FORM_KEYS.DATE_FILTER)

  useEffect(() => {
      setForm('from',new Date())
  }, [])

  function fromHandler(value: any) {
      setForm('from', value)
  }

  function clearFilter() {
      setForm('from',new Date())
  }

  return (
      <Box>
          <div className={'flex gap-2 items-end'}>
              <DatePicker
                  label={'From'}
                  id={'from'}
                  allowTextInput
                  value={_.get(form,'from') ? _.get(form,'from') : undefined}
                  onSelectDate={fromHandler}
              />
              {/*<DatePicker*/}
              {/*    label={'To'}*/}
              {/*    id = {'to'}*/}
              {/*    value={_.get(form,'to') ? _.get(form,'to') : undefined}*/}
              {/*    allowTextInput*/}
              {/*    ariaLabel="Select a date"*/}
              {/*    onSelectDate={toHandler}*/}
              {/*/>*/}
              <FontIcon
                  className={'ps-1 d-flex flex-column mb-2 justify-content-end'}
                  aria-label="Settings" iconName="ChromeClose"
                  style={{fontSize: 15, cursor: 'pointer'}}
                  onClick={clearFilter}
              />
          </div>
      </Box>

  )
}

export default DateFilter