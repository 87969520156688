import _ from "lodash";
import {socket} from "../../App";
import {useEffect} from "react";
import OrderList from "./components/order-list/order-list.component";
import OrderStatus from "./components/order-status/order-status";
import {METHOD_API} from "../../consts/method.const";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import {useDispatch} from "react-redux";
import {erpMethodApi} from "@dladio/service";
import {dispatchAction} from "@dladio/app-bridge";
import {orderSelection} from "../../service/table-selection";
import {showConfirmDialog} from "../../store/reducers/confirm-dialog";
import {ICommandBarItemProps} from "@fluentui/react";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../consts/local-store.const";
import DeliveryDocumentListComponent from "./components/delivery-document-list/delivery-document-list.component";
import {Box, DefaultCommandBar, Stack, TitleBar} from "@dladio/core-ui";
import {bulkPickingList, callMutationMethod, saveDoc} from "../../actions/actions";
import {middleErrorToast, middleSuccessToast, middleWarringToast} from "../../service/toast.service";
import {SOCKET_EVENTS, SOCKET_RESPONSE_EVENT, SOCKET_RESPONSE_STATUS} from "../../consts/socket.const";
import {getLocalStoreThunk, localStoreThunk, useLocalStore, useSocket} from "@dladio/hooks";

const {setStore: setSocketLog} = localStoreThunk(LOCAL_STORE_KEYS.SOCKET_LOG)

const WarehouseManagement = () => {

    const dispatch = useDispatch();
    const {message} = useSocket(socket, SOCKET_EVENTS.GENERATE_DELIVERY_NOTE_EVENT)
    const {message: socketError} = useSocket(socket, SOCKET_EVENTS.CONNECTION_ERROR)
    const {message: socketConnection} = useSocket(socket, SOCKET_EVENTS.CONNECT)
    const {model: PSModel, setStore: setPSModel}: any = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.WAREHOUSE_MANAGEMENT)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    useEffect(() => {
        if (message?.event === SOCKET_RESPONSE_EVENT.CREATE) {
            dispatch(getLocalStoreThunk(LOCAL_STORE_KEYS.SOCKET_LOG, 'lines') as any).then((socketLog: any) => {
                dispatch(setSocketLog('lines', [message, ...(socketLog ?? [])]) as any)
            })
        }

        if (message?.event === SOCKET_RESPONSE_EVENT.CREATE) {
            switch (message?.status) {
                case SOCKET_RESPONSE_STATUS.ERROR:
                    break
                case SOCKET_RESPONSE_STATUS.SUCCESS:
                    dispatch(erpMethodApi.util.invalidateTags(['exec']))
                    break
                case SOCKET_RESPONSE_STATUS.INFO:
                    break
                default:
            }
        }

        if (message?.event === SOCKET_RESPONSE_EVENT.PROCESS) {
            if (message?.status === SOCKET_RESPONSE_STATUS.INFO) {
                if (message?.payload === 'start') setPSModel(PAGE_STATES.LOADING, true)
            }

            if (message?.status === SOCKET_RESPONSE_STATUS.SUCCESS) {
                setPSModel(PAGE_STATES.LOADING, false)
                middleSuccessToast('Process Success')
            }

            if (message?.status === SOCKET_RESPONSE_STATUS.ERROR) {
                middleWarringToast('Process Terminated')
                console.log(message)
                setPSModel(PAGE_STATES.LOADING, false)
            }
        }
    }, [message])

    useEffect(() => {
        setStore(LOCAL_STORE_KEYS.SOCKET_CONNECTION, true)
    }, [socketConnection])

    useEffect(() => {
        if (!_.isEmpty(socketError)) {
            setStore(LOCAL_STORE_KEYS.SOCKET_CONNECTION, false)
            setPSModel(PAGE_STATES.LOADING, false)
        }
    }, [socketError])

    const cmdBarConfig: ICommandBarItemProps[] = [
        {
            key: '1',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: -1
                }, "https://helios.dlad.io/")
            },
        },
        {
            key: '2',
            text: 'Bulk Pick',
            iconProps: {iconName: 'BulkUpload'},
            onClick: () => {
                if (!_.isEmpty(orderSelection.getSelection())) {
                    dispatch(
                        showConfirmDialog({
                            title: "Confirmation Required!",
                            subtitle: "Are you sure you want to continue with the actions",
                            onConfirm: () => {
                                generateBulkPick()
                            },
                        })
                    );

                } else {
                    middleWarringToast('Please select at least one order')
                }
            },
        },
        {
            key: '3',
            text: 'Generate Delivery Document',
            iconProps: {iconName: 'ActivateOrders'},
            disabled: isLoading,
            onClick: () => {
                if (!_.isEmpty(orderSelection.getSelection())) {
                    dispatch(
                        showConfirmDialog({
                            title: "Confirmation Required!",
                            subtitle: "Are you sure you want to continue with the actions",
                            onConfirm: () => {
                                generateDeliveryDoc()
                            },
                        })
                    );

                } else {
                    middleWarringToast('Please select at least one order')
                }
            }
        },
        {
            key: '4',
            text: 'Select All Orders',
            iconProps: {iconName: 'CheckboxComposite'},
            disabled: isLoading,
            onClick: () => {
                if (orderSelection.isAllSelected()) orderSelection.setAllSelected(false)
                else orderSelection.setAllSelected(true)
            }
        },
        {
            key: '5',
            text: 'Print Queue',
            iconProps: {iconName: 'PageLink'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: '/app/page/914032724a'
                }, "https://helios.dlad.io/")
            },
        }
    ]

    const generateBulkPick: any = async () => {
        let {error}: any = await dispatch(bulkPickingList(orderSelection.getSelection() ?? []) as any)

        if (error) {
            middleErrorToast('Failed to generate PDF')
            return
        }

        let {error: saveDocError}: any = await dispatch(saveDoc('DBulkPicking', {
            "DLAD_BPL1Collection": orderSelection.getSelection()?.map((item: any) => ({
                "U_BaseEntryType": -1,
                "U_BaseEntry": item?.DocEntry
            }))
        }) as any)

        if (saveDocError) {
            middleErrorToast('Failed to save Document')
        }
    }

    const generateDeliveryDoc = async () => {
        dispatch(setSocketLog('lines', []) as any)
        setPSModel(PAGE_STATES.LOADING, true)

        let {error} = await dispatch(callMutationMethod(METHOD_API.GENERATE_DELIVERY_NOTES, {
            orders: orderSelection.getSelection()?.map((item: any) => (item?.DocEntry))
        }) as any)

        orderSelection.setAllSelected(false)

        if (!error) {
            // setPSModel(PAGE_STATES.LOADING, false)
            middleSuccessToast('Process Enqueued')

        } else {
            // setPSModel(PAGE_STATES.LOADING, false)
            middleErrorToast('Cannot Generate')
        }

    }

    const socketErrorIndicator = (
        <div
            className={`w-[8px] h-[8px] rounded-full ${select(LOCAL_STORE_KEYS.SOCKET_CONNECTION) ? 'bg-green-500' : 'bg-red-500'}`}>
        </div>
    )

    return (
        <div>
            <>
                <ConfirmDialog/>
                <TitleBar
                    title="Pick & Pack Manager"
                    subTitle={'WMS'}
                />
                <DefaultCommandBar actions={cmdBarConfig as any}/>
                <Stack>
                    <Box>
                        <div className={'pt-1'}>
                            <text className={'font-semibold'}>Orders</text>
                            <OrderList/>
                        </div>
                    </Box>

                    <Box>
                        <div className={'pt-1'}>
                            <text className={'font-semibold'}>Orders to be picked</text>
                            <DeliveryDocumentListComponent type={'PICK'}/>
                        </div>
                    </Box>

                    <Box>
                        <div className={'pt-1'}>
                            <text className={'font-semibold'}>Orders to be packed</text>
                            <DeliveryDocumentListComponent type={'PACK'}/>
                        </div>
                    </Box>

                    <Box>
                        <div className={'pt-1'}>
                            <div className={'flex flex-row gap-2 items-center'}>
                                <text className={'font-semibold'}>Status</text>
                                {socketErrorIndicator}
                            </div>
                            <OrderStatus/>
                        </div>
                    </Box>
                </Stack>
            </>
        </div>
    )
}

export default WarehouseManagement