import { SelectionMode } from "@fluentui/react";
import { defaultSelection, orderSelection } from "../service/table-selection";

export const selections: any = {
    ORDER: orderSelection,
    PICK: defaultSelection,
    PACK: defaultSelection
}

export const selectionMode: any = {
    ORDER: SelectionMode.multiple,
    PICK: SelectionMode.none,
    PACK: SelectionMode.none
}