import _ from 'lodash'
import {useLocalStore} from "@dladio/hooks";
import {Box, Stack, TupleCardLine} from "@dladio/core-ui";
import {DOCUMENT_MODEL_KEYS, LOCAL_STORE_KEYS, ORDER_COMPLETION_KEYS} from "../../../../consts/local-store.const";

const OrderInformation = () => {
    const {model: OCModel} = useLocalStore(LOCAL_STORE_KEYS.ORDER_COMPLETION)
    const {model: DMModel} = useLocalStore(LOCAL_STORE_KEYS.DOCUMENT_MODEL)

    const fulFillStatus = _.get(OCModel, ORDER_COMPLETION_KEYS.FULFILL_STATUS)
    const orderNumber = _.get(DMModel, DOCUMENT_MODEL_KEYS.ORDER_NUMBER)
    const cardCode = _.get(DMModel, DOCUMENT_MODEL_KEYS.CARD_CODE)
    const cardName = _.get(DMModel, DOCUMENT_MODEL_KEYS.CARD_NAME)
    const address = _.get(DMModel, DOCUMENT_MODEL_KEYS.ADDRESS_OBJ)

    const markup = fulFillStatus && (
        <Box title={'Order Information'}>
            <Stack className={'flex-col w-[400px]'}>
                <TupleCardLine title={'Order No'} value={`SO-${orderNumber}`}/>
                <TupleCardLine title={'BP Code #'} value={cardCode}/>
                <TupleCardLine title={'Name'} value={cardName}/>
                <TupleCardLine title={'Address'} value={`${address?.StreetS} ${address?.CityS} ${address?.StateS} ${address?.CountryS}`}/>
            </Stack>
        </Box>
    )
    return <>{markup}</>
}

export default OrderInformation