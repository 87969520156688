import _ from "lodash";
import Table from "../../../../components/table/table";
import {QUERIES} from "../../../../consts/queries.const";
import {Spinner} from "@fluentui/react";
import {useDerive} from "@dladio/service";
import {useLocalStore} from "@dladio/hooks";
import {orderSelection} from "../../../../service/table-selection";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {ORDER_TABLE_CONFIG} from "../../../../consts/table-config.const";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";
import {Dropdown, IDropdownOption, IDropdownStyles} from "@fluentui/react/lib/Dropdown";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {width: 300},
};

const options: IDropdownOption[] = [
    {key: QUERIES.ONLINE_SALES, text: 'Online Sales Orders'},
    {key: QUERIES.WHOLESALE_SALES, text: 'Wholesale Sales Orders'},
    {key: QUERIES.INTERNATIONAL_SALES, text: 'International Sales Orders'},
    {key: QUERIES.OPEN_PREPAYMENT_SALES, text: 'Open Prepayment Sales Orders'},
];

const OrderList = () => {
    const {select, setStore}: any = useLocalStore(LOCAL_STORE_KEYS.WAREHOUSE_MANAGEMENT)
    const {model: PSModel}: any = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)
    const orderType = select(LOCAL_STORE_KEYS.ORDER_TYPE)

    const isLoading = _.get(PSModel, PAGE_STATES.LOADING)

    const {value, isFetching} = useDerive('exec', {
        options: {
            method: EXEC_QUERY_METHOD, body: {
                name: select(LOCAL_STORE_KEYS.ORDER_TYPE) ? select(LOCAL_STORE_KEYS.ORDER_TYPE) : QUERIES.ONLINE_SALES
            }
        },
        path: 'message'
    })

    const onChangeDropDown = (event: any, keyValuePair: any) => {
        orderSelection.setAllSelected(false)
        setStore(LOCAL_STORE_KEYS.ORDER_TYPE, keyValuePair?.key)
    }

    const loadingMarkUp: any = (
        <div>
            <div className={'h-[200px] flex flex-col justify-center'}>
                <Spinner label="Loading..."/>
            </div>
        </div>
    )

    return (
        <>
            <div className="bg-orange-200 mt-2 p-3 rounded border border-yellow-500">
                <h1>{`${value?.length ?? 0} documents found`}</h1>
            </div>

            <div className={'flex justify-start items-center'}>
                <Dropdown
                    placeholder="Select an option"
                    label="Order type"
                    defaultSelectedKey={orderType ? orderType : QUERIES.ONLINE_SALES}
                    onChange={onChangeDropDown}
                    options={options}
                    styles={dropdownStyles}
                />
            </div>
            <div className="h-[calc(100vh-265px)] flex flex-col gap-2 overflow-x-hidden overflow-y-auto">
                {isFetching || isLoading ? loadingMarkUp :
                    <Table config={ORDER_TABLE_CONFIG} type={'ORDER'} model={value ?? []}/>}
            </div>
        </>
    )
}

export default OrderList