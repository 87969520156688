import _ from "lodash";
import {METHOD_API} from "../../../../consts/method.const";
import {useDispatch} from "react-redux";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import {useLocalStore} from "@dladio/hooks";
import {dispatchAction} from "@dladio/app-bridge";
import {showConfirmDialog} from "../../../../store/reducers/confirm-dialog";
import {extendedExecMethod} from "../../../../actions/actions";
import {ICommandBarItemProps} from "@fluentui/react";
import {completeOrderSelection} from "../../../../service/table-selection";
import {DefaultCommandBar, TitleBar} from "@dladio/core-ui";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";
import {middleErrorToast, middleSuccessToast, middleWarringToast} from "../../../../service/toast.service";

const Header = () => {

    const dispatch = useDispatch();
    const {model,setStore}:any = useLocalStore(LOCAL_STORE_KEYS.PAGE_STATES)

    const cmdBarConfig: ICommandBarItemProps[] = [
        {
            key: '1',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: () => {
                dispatchAction({
                    action: 'navigate',
                    payload: -1
                }, "https://helios.dlad.io/")
            },
        },
        {
            key: '2',
            text: 'Generate Invoice',
            iconProps: {iconName: 'Generate'},
            disabled: _.get(model,'loading'),
            onClick: () => {
                if (!_.isEmpty(completeOrderSelection.getSelection())) {
                    dispatch(
                        showConfirmDialog({
                            title: "Confirmation Required!",
                            subtitle: "Are you sure you want to continue with the actions",
                            onConfirm: () => {
                                generateInvoiceList()
                            },
                        })
                    );

                } else {
                    middleWarringToast('Please select at least one order')
                }
            },
        },
    ]

    const generateInvoiceList = () => {
        setStore(PAGE_STATES.LOADING, true)
        dispatch(extendedExecMethod(METHOD_API.GENERATE_INVOICE, {
            doc_list: completeOrderSelection.getSelection()?.map((line: any) => line?.ODLNDocEntry)
        }, {forceRefetch: true}) as any)
            .then((data: any) => middleSuccessToast("Process Enqueued"))
            .catch((e: any) => middleErrorToast("Cannot Process")).finally(() => completeOrderSelection.setAllSelected(false))
    }

    return (
        <>
            <ConfirmDialog/>
            <TitleBar
                title="Complete Orders"
                subTitle={'WMS'}
            />
            <DefaultCommandBar actions={cmdBarConfig as any}/>
        </>
    )
}

export default Header