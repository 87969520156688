export enum FK {
    DOC_ENTRY = 'docEntry',
    MASTER_CARTON = 'cartonCode',
    WEIGHT = 'weight',
    DEPTH= 'U_Depth',
    LENGTH = 'U_Length',
    WIDTH = 'U_Width'
}

export enum FORM_KEYS {
    PACKING_FORM = 'packing-form',
    DATE_FILTER = 'date-filter',
    MISCCARTON ='mis-carton',
    EditeForm ='edite-form',
}