export enum SOCKET_EVENTS {
    GENERATE_DELIVERY_NOTE_EVENT = "generate_delivery_note",
    CONNECTION_ERROR = 'connect_error',
    GENERATE_INVOICE = '',
    CONNECT = 'connect',
    DISCONNECT = 'disconnect'
}

export enum SOCKET_RESPONSE_EVENT {
    CREATE = 'CREATE',
    PROCESS = 'PROCESS',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum SOCKET_RESPONSE_STATUS {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    INFO = 'INFO'
}