    import {Login} from "@microsoft/mgt-react";
import {useRoutes} from "react-router-dom";
import useIsSignedIn from "../hooks/use-is-signed-in";
import CompleteOrderList from "../pages/complete-order-list/complete-order-list";
import WarehouseManagement from "../pages/warehouse-management/warehouse-management";
import CompleteOrderPacking from "../pages/complete-order-packing/complete-order-packing";

const RouterConfigs = () => {
    const [isSignedIn] = useIsSignedIn();

    const element = useRoutes(
        [
            {
                path: "/",
                element:  !isSignedIn ? <Login/> : <WarehouseManagement/>,
            },
            {
                path: "/complete-order",
                element: <CompleteOrderPacking/>,
            },
            {
                path: "/completed-order-list",
                element: <CompleteOrderList/>,
            },
        ]);

    return element;
};

export default RouterConfigs;
