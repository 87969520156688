import _ from "lodash";
import {socket} from "../../App";
import {useDispatch} from "react-redux";
import {erpMethodApi} from "@dladio/service";
import React, {useEffect} from "react";
import {localStoreThunk, useSocket} from "@dladio/hooks";
import {LOCAL_STORE_KEYS, PAGE_STATES} from "../../consts/local-store.const";
import {middleErrorToast, middleSuccessToast, middleWarringToast} from "../../service/toast.service";
import {SOCKET_EVENTS, SOCKET_RESPONSE_EVENT, SOCKET_RESPONSE_STATUS} from "../../consts/socket.const";

const {setStore: setPageState} = localStoreThunk(LOCAL_STORE_KEYS.PAGE_STATES)

const SocketManager = ({event}:any) => {
    const dispatch = useDispatch()
    const {message} = useSocket(socket, event)
    const {message: socketError} = useSocket(socket, SOCKET_EVENTS.CONNECTION_ERROR)

    useEffect(() => {
        if (message?.event === SOCKET_RESPONSE_EVENT.CREATE) {
            switch (message?.status) {
                case SOCKET_RESPONSE_STATUS.ERROR:
                    middleErrorToast(`Cannot Generate`, `${message?.payload?.id} - ${message?.payload?.msg}`)
                    break
                case SOCKET_RESPONSE_STATUS.SUCCESS:
                    middleSuccessToast(`Successfully Generated`, `${message?.payload?.id} - ${message?.payload?.msg}`)
                    break
                case SOCKET_RESPONSE_STATUS.INFO:
                    break
                default :
            }
        }

        if (message?.event === SOCKET_RESPONSE_EVENT.PROCESS) {
            if (message?.status === SOCKET_RESPONSE_STATUS.INFO) {
                if (message?.payload === 'start') dispatch(setPageState(PAGE_STATES.LOADING, true) as any)
            }

            if (message?.status === SOCKET_RESPONSE_STATUS.ERROR) {
                middleWarringToast('Process Terminated')
                console.log(message)
                dispatch(setPageState(PAGE_STATES.LOADING, false) as any)
            }

            if (message?.status === SOCKET_RESPONSE_STATUS.SUCCESS) {
                middleSuccessToast('Process Complete')
                dispatch(erpMethodApi.util.invalidateTags(['exec']))
                dispatch(setPageState(PAGE_STATES.LOADING, false) as any)
            }
        }
    }, [message])

    useEffect(() => {
        if (!_.isEmpty(socketError)) {
            dispatch(setPageState(PAGE_STATES.LOADING, false) as any)
        }
    }, [socketError])

    return null

}

export default React.memo(SocketManager)