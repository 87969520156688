import _ from "lodash";
import {Link} from "@fluentui/react";
import {PAGE_NO} from "../../../../../consts/pages.const";
import {dispatchAction} from "@dladio/app-bridge";

const DeliveryDocLine = (data: any) => {
    let {Id, DocNum, DocDate, OrderDocNumber = '', type, DocEntry, OrderDocEntry}: any = data

    const onClickDocLine = () => {
        if (type === 'PACK') dispatchAction({
            action: 'navigate',
            payload: `page/${PAGE_NO.COMPLETE_ORDER}?id=${DocEntry}`
        }, "https://helios.dlad.io/")
    }

    const urlByType = {
        order: PAGE_NO.ORDER + `?id=${OrderDocEntry}`,
    }

    const onClickLink = (type: any) => {
        dispatchAction({
            action: 'navigate',
            payload: `page/${_.get(urlByType, type)}`
        }, "https://helios.dlad.io/")
    }

    return (
        <div className={` ${type === 'PACK' ? 'cursor-pointer' : ''} w-full p-3`}
             onClick={onClickDocLine}>
            <div className={'flex w-full gap-5 justify-center items-center'}>
                <div className="flex gap-5 w-full justify-start items-start">
                    <div>
                    <h1 className={"font-bold"}>ID #</h1>
                        <p>Delivery Document</p>
                        <p>Order Number</p>
                        <p>Order Date</p>
                    </div>
                    <div>
                    <h1 className={"font-bold"}>
                            {/*<Link className={'truncate'}*/}
                            {/*      onClick={onClickLink}*/}
                            {/*      underline> {DocNum}*/}
                            {/*</Link>*/}
                            {Id}
                        </h1>
                        <h1 >
                            {/*<Link className={'truncate'}*/}
                            {/*      onClick={onClickLink}*/}
                            {/*      underline> {DocNum}*/}
                            {/*</Link>*/}
                            {DocNum}
                        </h1>
                        <h1 className={"font-bold"}>
                            <Link className={'truncate'}
                                  onClick={() => onClickLink('order')}
                                  underline> {OrderDocNumber}
                            </Link>
                        </h1>
                        <h1>{DocDate}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryDocLine