export enum QUERIES {
    ONLINE_SALES = '942d274653',
    WHOLESALE_SALES='540174b624',
    INTERNATIONAL_SALES = 'e7fcc8ac7e',
    OPEN_PREPAYMENT_SALES = '987fa6fcb0',
    GET_STATUS = 'a256383c7e',
    GET_DELIVERY_LIST = '67f5cdd4ee',
    GET_DLAD_ODLN_DOC = '4073d0ce7b',
    GET_CARTON_DETAILS = '954b603b6f',
    SELECTED_CARTON_DETAILS = '7d759ac273',
    GET_COMPLETED_ORDERS = '8653a91be5'
}