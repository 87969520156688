import {Text} from "@fluentui/react";
import {Box, createStandaloneToast, Spinner} from "@chakra-ui/react";

const toast = createStandaloneToast();

const middleErrorToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'error',
        duration: 2500,
        isClosable: true
    })
}

const middleSuccessToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'success',
        duration: 2500,
        isClosable: true
    })
}

const middleWarringToast = (title: string, description = '') => {
    toast({
        title,
        description,
        status: 'warning',
        duration: 2500,
        isClosable: true
    })
}

const loadingToast = (title: string) => {
    toast({
        render: () => (
            <Box rounded={'3px'} color='white' p={3} bg='black'>
                <div className={'flex gap-2'}>
                    <Spinner className={'font-bold'} color={'white'}/>
                    <Text className={'text-white font-bold'}>{title}</Text>
                </div>
            </Box>
        ),
    })
}

export {
    middleErrorToast,
    middleSuccessToast,
    middleWarringToast,
    loadingToast
}