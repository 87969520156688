import _ from "lodash";
import {Text} from "@fluentui/react";
import {QUERIES} from "../../../../consts/queries.const";
import {useDerive} from "@dladio/service";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";

const CartonDetails = ({cartonId}: any) => {

    const {value}: any = useDerive('exec', {
        options: {
            "method": EXEC_QUERY_METHOD,
            "body": {
                "name": QUERIES.GET_CARTON_DETAILS,
                "id": cartonId
            },
        },
        path: 'message'
    })

    return (
        <div className={'space-x-5 font-Popings mt-2'}>
            <Text className={'font-bold'}>Length : {_.get(value, 'length')}</Text>
            <Text className={'font-bold'}>Width : {_.get(value, 'width')}</Text>
            <Text className={'font-bold'}>Depth : {_.get(value, 'depth')}</Text>
        </div>
    )
}

export default CartonDetails