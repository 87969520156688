import _ from "lodash";

const bgColors:any ={
    Picked:'bg-red-500',
    Packed:'bg-green-500',
    Completed:'bg-blue-500'
}

const OrderStatusLine = ({label,value}: any) => {

  return (
      <div className={"rounded border w-full p-3"}>
        <div className={'flex w-full gap-5 justify-center items-center'}>
          <div className="flex gap-5 w-full justify-between items-start">
            <div className={''}>
              <h1 className={"font-bold"}>{label}</h1>
            </div>
            <div className={'justify-end'}>
                <div className={'flex'}>
                    <h1 className={`font-bold ${_.get(bgColors,label)} rounded px-2 text-white`}>{value}</h1>
                </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default OrderStatusLine