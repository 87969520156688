import _ from "lodash";
import {FK} from "../../../../consts/form.const";
import {QUERIES} from "../../../../consts/queries.const";
import {Box, Stack} from "@dladio/core-ui";
import {execMethod} from "../../../../actions/actions";
import {useDispatch} from "react-redux";
import {erpMethodApi, SERVICE} from "@dladio/service";
import {useCallback, useEffect} from "react";
import {localStoreThunk, useForm} from "@dladio/hooks";
import {PrimaryButton, TextField} from "@fluentui/react";
import {LOCAL_STORE_KEYS, ORDER_COMPLETION_KEYS, PAGE_STATES} from "../../../../consts/local-store.const";

const {setStore: setOrderModel} = localStoreThunk(LOCAL_STORE_KEYS.DOCUMENT_MODEL)
const {setStore: setOrderCompletion} = localStoreThunk(LOCAL_STORE_KEYS.ORDER_COMPLETION)
const {setStore: setPageState} = localStoreThunk(LOCAL_STORE_KEYS.PAGE_STATES)

const SearchBar = () => {
    const dispatch = useDispatch()
    const {form, formController} = useForm()
    const {data, isFetching, isError} = erpMethodApi.endpoints.execMethodApi.useQueryState({
        method: SERVICE.EXEC_QUERY_METHOD,
        body: {
            name: QUERIES.GET_DLAD_ODLN_DOC,
            id: _.get(form, FK.DOC_ENTRY)
        }
    })

    useEffect(() => {
        if (!_.isEmpty(data) && !isError) {
            dispatch(setOrderModel(_.get(data, 'message')) as any)
            dispatch(setOrderCompletion(ORDER_COMPLETION_KEYS.FULFILL_STATUS, _.get(data, 'message.U_FulFillStatus')) as any)
        }
    }, [data])

    //handler page loading state
    useEffect(() => {
        if (isFetching) dispatch(setPageState(PAGE_STATES.LOADING, true) as any)
        else dispatch(setPageState(PAGE_STATES.LOADING, false) as any)
    }, [isFetching])

    //error handling
    useEffect(() => {
        if (isError) {
            dispatch(setOrderModel({}) as any)
            dispatch(setOrderCompletion(ORDER_COMPLETION_KEYS.FULFILL_STATUS, null) as any)
        }
    }, [isError])

    const docEntry = _.get(form, FK.DOC_ENTRY)

    const search = () => {
        dispatch(execMethod(SERVICE.EXEC_QUERY_METHOD, {
            name: QUERIES.GET_DLAD_ODLN_DOC,
            id: docEntry
        }, {forceRefetch: true}) as any)
            .then(() => {
            })
            .catch(() => {
            })
    }

    const keyUpHandler = (e: any) => {
        if (e.key === "Enter") {
            search();
        }
    }

    return (
        <div onKeyUp={keyUpHandler}>
            <Box>
                <Stack className={'gap-2 align-baseline'}>
                    <TextField
                        value={_.get(form, FK.DOC_ENTRY)}
                        className={'w-[286px]'}
                        name={FK.DOC_ENTRY}
                        label={'Search document'}
                        onChange={formController}
                    />
                    <div className={'flex flex-col justify-end'}>
                        <PrimaryButton
                            className={'h-[25px] py-0'}
                            onClick={search}>
                            Search
                        </PrimaryButton>
                    </div>
                </Stack>
            </Box>
        </div>
    )
}

export default SearchBar